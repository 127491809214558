import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import Listing from 'Components/Listing/Listing';
import ListingContent from '../../Listing/components/ListingContent/ListingContent';
import AsyncBundles from './AsyncBundles';
import { bundlesSelector } from 'Selectors/bundles';
import withConfig from 'HOCs/withConfig/withConfig';
import css from './HomeListing.scss';
import { itemsPageIndexSelector } from 'Selectors/items';
import { isEmpty } from 'Helpers/objects';
import { ITEM_SOURCE } from 'Constants/items';

export class HomeListing extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType(
                    [PropTypes.number, PropTypes.string]
                ).isRequired,
                title: PropTypes.string.isRequired
            })
        ).isRequired,
        isFetching: PropTypes.bool,
        onViewItem: PropTypes.func,
        location: PropTypes.object,
        enabledRealImpressions: PropTypes.bool,
        onLoadNextPage: PropTypes.func,
        header: PropTypes.node,
        showLoadMore: PropTypes.bool,
        config: PropTypes.shape({
            get: PropTypes.func
        }).isRequired,
        bundles: PropTypes.object,
        autoScroll: PropTypes.bool,
        page: PropTypes.string,
        extraTrackAttr: PropTypes.object.isRequired
    };

    static defaultProps = {
        onViewItem: () => {},
        onLoadNextPage: () => {},
        autoScroll: false,
        page: '0'
    };

    showBundles = () => {
        const { bundles, config } = this.props;
        const isBundlesEnabled = config.get('bundles').enabled;

        return isBundlesEnabled && !isEmpty(bundles);
    }

    render() {
        const {
            items, isFetching, onViewItem,
            location, enabledRealImpressions,
            onLoadNextPage, header, showLoadMore,
            autoScroll, page, extraTrackAttr
        } = this.props;

        return (
            <React.Fragment>
                {
                    this.showBundles() && <AsyncBundles />
                }
                <Listing
                    header={ (items && items.length) ? header : null }
                    onLoadNextPage={ onLoadNextPage }
                    showLoadMore={ showLoadMore }
                    className={ css.listing }
                    autoScroll={ autoScroll }
                    isFetching={ isFetching }
                    page={ Number(page) }
                >
                    <ListingContent
                        items={ items }
                        isFetching={ isFetching }
                        enabledRealImpressions={ enabledRealImpressions }
                        onViewItem={ onViewItem }
                        location={ location }
                        visualizationType="grid"
                        itemPostPlaceholderOrigin="home"
                        fourColumns
                        source={ ITEM_SOURCE.HOME }
                        extraTrackAttr={ extraTrackAttr }
                        isShowVideoTag={ false }
                    />
                </Listing>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    bundles: bundlesSelector(state),
    page: itemsPageIndexSelector(state)

});

export default compose(
    withConfig,
    connect(mapStateToProps),
    injectIntl
)(HomeListing);
